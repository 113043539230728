<template>
	<b-card
		overlay
		:img-src="backGround"
		img-alt="Card Image"
		:title="info.key"
		img-height="150rem"
	>
		<b-card-text>
			{{ info.info }}
		</b-card-text>
	</b-card>
</template>

<script>
import { BCardText, BCard,} from "bootstrap-vue";

export default {
	name: "HeaderInfoMail",
	components: {
		BCard,
		BCardText,
	},
	data() {
		return {
			backGround : require('@/assets/images/banner/banner.png')
		};
	},
	props: ['info'],

	methods: {},
};
</script>
