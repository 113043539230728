<template>
  <div ref="formContainer">
    <AddHeaderTitle :Header='Header'/>
    <AppHeaderInfoMail :info="mail" />
    <b-tabs>
      <b-tab v-for="l in languages" :key="l" >
        <template #title>
          <b-img
            :src="getFlag(l)"
            height="14px"
            width="22px"
            alt=""
          />
          <span class="ml-50 text-body">{{ l }}</span>
        </template>
        <div  v-for="item in mail.values" :key="item.lang">
          <b-row>
            <b-col md="8">
              <b-card
              class="blog-edit-wrapper"
              >
                <b-form class="mt-2">
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Name"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="blog-edit-title"
                          v-model="item.name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Subject"
                        label-for="blog-edit-category"
                        class="mb-2"
                      >
                        <b-form-input
                          id="blog-edit-title"
                          v-model="item.subject"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Content"
                        label-for="blog-content"
                        class="mb-2"
                      >
                        <div class="quill-wrap">
                        <quill-editor
                          v-model="item.content"
                          :size="20"
                        />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      class="mt-50"
                    >
                      <b-button
                        @click="editMail(mail.key ,item.lang, item)"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="mr-1"
                      >
                        Modifier
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card> 
            </b-col> 
            <b-col md="4">
              <b-card
                no-body
                class="card-developer-meetup"
              >
                <div class="bg-light-primary rounded-top text-center">
                  <b-img
                    :src="require('@/assets/images/illustration/email.svg')"
                    alt="Meeting Pic"
                    height="170"
                  />
                </div>
                <b-card-body>
                  <!-- metting header -->
                  <div class="meetup-header d-flex align-items-center">
                    <div class="my-auto">
                      <b-card-title class="mb-25">
                        Liste des params 
                      </b-card-title>
                    </div>
                  </div>
                  <!--/ metting header -->
                  <!-- media -->
                  <b-media
                    no-body
                  >
                    <b-media-aside class="mr-1">
                      <b-avatar
                        rounded
                        variant="light-success"
                        size="34"
                      >
                        <feather-icon
                          icon="AlertCircleIcon"
                          size="18"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">
                        Information
                      </h6>
                      <small>{{ mail.params[0].info }}</small>
                    </b-media-body>
                  </b-media>
                  <b-media
                    no-body
                  >
                    <b-media-aside class="mr-1">
                      <b-avatar
                        rounded
                        variant="light-success"
                        size="34"
                      >
                        <feather-icon
                          icon="ArchiveIcon"
                          size="18"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-1">
                        Params
                      </h6>
                      <b-row>
                        <div class="mr-1" v-for="param in mail.params" :key="param.key">
                          <b-badge pill variant="light-primary">{{ param.key }}</b-badge>
                        </div>
                      </b-row>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </b-col>     
          </b-row>
        </div> 
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BForm, BRow, BCol, BFormGroup, BFormInput, BButton, BTabs, BTab,BImg,BCard, BCardBody, BCardTitle, BMedia, BMediaAside, BAvatar, BMediaBody,BBadge } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import AppHeaderInfoMail from "@/app/views/components/AppHeaderInfoMail.vue";
import Ripple from 'vue-ripple-directive';
import AddHeaderTitle from '@/app/views/components/AppHeaderTitle.vue';
import  { Mail } from '@/mixins/mail.js';
import { Config } from "@/mixins/config.js";

export default {
  name: "MailDetail",
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    quillEditor,
    AddHeaderTitle,
    AppHeaderInfoMail, BTabs, BTab,
    BImg, BCardBody, BCardTitle, BMedia, BMediaAside, BAvatar,BMediaBody,
    BBadge
  },
  directives: {
    Ripple,
  },
   data() {
    return {
      mails: {},
      mail: [],
      mailKey:"",
      Header: {
        config: "configuration",
				listLink: "/configuration/mail/list",
				list: "email",
				detailLink: "",
				detail: "email-detail",
			},
      languages : [],
      langArray: [{
        lang: "fr_FR",
        img : require('@/assets/images/flags/fr.png')
        },
        {
          lang: "en_EN",
          img : require('@/assets/images/flags/en.png')
        },
        {
          lang: "es_ES",
          img : require('@/assets/images/flags/es.png')
        },
      ],
    }
  },
  mixins : [ Mail, Config ],
  async mounted() {
    await this.getLanguages()
    await this.getKey();
    await this.loadTransDetail(); 
  },
  methods: {
    // function to get key in url 
     async getKey() {
      this.mailKey = await this.$route.params.id
    },
    // API to load mail detail by key
    async loadTransDetail() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
			});
      try {
        const datas = await this.getTransList(this.mailKey);
        if (datas.status === 1) {
          this.mails = datas;
          this.mail = this.mails.datas;  
        } else {
          this.$sweetError("AF-49");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-49");
      }
    },
    async editMail(key,lang, values) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
			});
      try {
        const response = await this.editMailDetail(key,lang, values);
        if (response.status === 1) {
          this.$sweetNotif("Modification réussie");
        } else {
          this.$sweetError("AF-14");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-14");
      }
    },
    async getLanguages() {
			const response = await this.getConfigDetail("LANGUAGES");
			if (response.status === 1) {
				this.languages = response.datas.values;
			} else {
				this.$sweetError("AF-12");
			}
		},
    getFlag(lang) {
      try {
        const flag = this.langArray.find(element => element.lang == lang);
        return flag.img  
      } catch {
        this.$sweetError("AF-13");
      }
      
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
.ql-editor{
    min-height:150px;
}
</style>
